if(Array.from(jQuery('body')[0].classList).includes("single-pa_how_to")) {
    const container = jQuery('.how-to-content');
    const containerTop = container.offset().top;
    const toc = jQuery('.how-to-toc');
    const spacing = -50;
    const bottomSpacing = 100;

    document.addEventListener("scroll", () => {
        let scrollTop = jQuery(window).scrollTop();
        let headerHeight = jQuery('.site-header').height();
        let containerScrollTop = scrollTop - containerTop;
        let containerHeaderHeight = containerTop - headerHeight - spacing;
        let tocBottomTopHeight = container.height() - toc.height() - bottomSpacing;
        let relativeTocTop = containerScrollTop + headerHeight + spacing;

        // Check if we have scrolled above the usable area
        if(containerHeaderHeight > scrollTop) {
            toc.css('top', "0px");
            return;
        }

        // Check if we have scrolled below the usable area
        if(relativeTocTop > tocBottomTopHeight) {
            toc.css('top', tocBottomTopHeight + "px");
            return;
        }

        if(containerHeaderHeight < scrollTop) {
            toc.css('top', relativeTocTop + "px");
        }
    });
}
